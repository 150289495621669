import React, { Component } from 'react';
import * as LANGCONSTANT from '../constants/language';
import * as ROUTES from '../constants/routes';
import { bindActionCreators } from 'redux';
import * as dataAction from '../redux/actions/languageAction';
import * as guardAction from '../redux/actions/guardAction';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { SpinnerComponent as Spinner } from './spinner/SpinnerComponent';
import { spinnerName } from '../constants/data';
import { spinnerService } from '../services/spinner.service';
import { CONFIG } from '../configuration/config';
import { CallApi } from '../services/service';
import LanguageUtils from '../utils/LanguageUtils';
import { setData, getData, removeData } from '../containers/storageHandler';
import AlertModal from './modals/AlertModal';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHome: true,
      show: false,
      modalIsOpen: false,
      userName: '',
      password: '',
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#000000';
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  async setGuard() {
    const dataObj = {
      guard: true
    };
    setData('guard', true);
    removeData('configureData');
    removeData('pageName');
    await this.props.guardAction.guardData(dataObj);
  }

  handleModalSubmit(event) {
    this.closeModal();
    const langType = LanguageUtils.getLangType(this.props);
    if (this.state.userName && this.state.password) {
      const loginObj = {
        username: this.state.userName,
        password: this.state.password
      };
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      CallApi('POST', CONFIG.authorizeSso, {}, loginObj, langType, "authorizeLogin", this);
      event.preventDefault();
    } else {
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.EnterDetails);
    }
  }

  authorizeLogin = (response) => {
    if (response.result.status === true) {
      setData('auth', response.result.token);
      this.setGuard();
      this.props.history.push(ROUTES.CONFIGURATION);
    } else {
      AlertModal.showAlert(response.message)
    }
  }


  async languageChange() {
    if (!!this.props.languageData) {
      if (this.props.languageData.language === 'en') {
        const languageData = {
          language: 'es',
          langType: 'ESP'
        };
        setData('languageData', languageData);
        this.props.actions.languageData(languageData);
      } else if (this.props.languageData.language === 'es') {
        const languageData = {
          language: 'en',
          langType: 'ENG'
        };
        setData('languageData', languageData);
        this.props.actions.languageData(languageData);
      }
    } else {
      await this.setLanguageData();
      this.languageChange();
    }
  }

  setLanguageData() {
    const languageData = {
      language: 'en',
      langType: 'ENG'
    };
    setData('languageData', languageData);
    this.props.actions.languageData(languageData);
  }

  changeText() {
    this.setState({
      show: true
    });
    setTimeout(() => {
      this.setState({
        show: false
      });
    }, 3000);
  }

  configure() {
    this.openModal();
  }

  home() {
    if (!!getData('pageName')) {
      const pagename = getData('pageName').pageName;
      if (pagename === 'checkin') {
        this.props.history.push(ROUTES.CHECKINGATEPASS);
      } else if (pagename === 'checkout') {
        this.props.history.push(ROUTES.EXITGATE);
      } else {
        this.props.history.push(ROUTES.CONFIGURATION);
      }
    } else {
      this.props.history.push(ROUTES.CONFIGURATION);
    }
  }

  render() {
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        <Spinner name={spinnerName.LOGIN_SPINNER} />
        <header>
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <a
                href="javaScript:void(0)"
                onClick={() => this.home()}
                className="home-icon"
              >
                <img
                  src={require('../assets/images/home-icon.png')}
                  alt=""
                  hidden={this.props.showHome}
                />
              </a>
              {this.state.showHome}
              <a className="logo" href="javaScript:void(0)">
                <img src={require('../assets/images/white-logo.png')} alt="" />
              </a>
              {
                <span style={{ float: 'right' }}>
                  <span>
                    <input
                      className="input-dots"
                      type="button"
                      value={LANGCONSTANT[langType].LABELS.Configure}
                      onClick={() => this.configure()}
                      hidden={!this.state.show}
                    />
                    <img
                      className="input-dots"
                      src={require('../assets/images/dots.png')}
                      onClick={() => this.changeText()}
                      hidden={this.state.show}
                    />
                  </span>
                  <input
                    type="button"
                    className="language-btn btn"
                    value={
                      this.props.languageData
                        ? this.props.languageData.language === 'en'
                          ? 'Español'
                          : 'English'
                        : 'Español'
                    }
                    onClick={() => this.languageChange()}
                    hidden={this.props.showLang ? this.props.showLang : false}
                  />
                </span>
              }
            </div>
          </div>
        </header>
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal"
          >
            <div className="modal confirmation-modal-dialog">
              <div className="delivery-access-body">
                <h5
                  ref={subtitle => (this.subtitle = subtitle)}
                  style={{ textAlign: 'center' }}
                >
                  {LANGCONSTANT[langType].LABELS.Login}
                </h5>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder={LANGCONSTANT[langType].LABELS.UserName}
                    className="form-control"
                    name="userName"
                    onChange={event => this.handleChange(event)}
                    autoComplete="off"
                    autoFocus={true}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    placeholder={LANGCONSTANT[langType].LABELS.Password}
                    className="form-control"
                    name="password"
                    onChange={event => this.handleChange(event)}
                  />
                </div>
                <div className="d-flex">
                  <button
                    className="btn btn-disable"
                    style={{ backgroundColor: '#797979' }}
                    onClick={this.closeModal}
                  >
                    {LANGCONSTANT[langType].BUTTONS.No}
                  </button>
                  <button
                    className="btn btn-submit"
                    onClick={event => this.handleModalSubmit(event)}
                  >
                    {LANGCONSTANT[langType].BUTTONS.Yes}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  actions: propTypes.object.isRequired,
  guardAction: propTypes.object.isRequired
};

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(dataAction, dispatch),
    guardAction: bindActionCreators(guardAction, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
