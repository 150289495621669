import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import PageProgressComponent from '../../common/PageProgressComponent';
import * as LANGCONSTANT from '../../constants/language';
import LanguageUtils from '../../utils/LanguageUtils';
import { connect } from 'react-redux';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Header from '../../common/Header';
import IdleTimerComponent from '../../common/idleCheck/IdleTimerComponent';
import AlertModal from '../../common/modals/AlertModal';

class DeliveryAndReturnComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryNumber: '',
      pageNumber: 1,
      layoutName: "default",
      inputName: "input1",
      input: {}
    };
  }

  back() {
    this.props.history.goBack();
  }

  // handleChange(event) {
  //   this.setState({
  //     deliveryNumber: event.target.value
  //   });
  // }

  handleSubmit(event) {
    if (event !== "{enter}") {
      event.preventDefault();
    }
    const langType = LanguageUtils.getLangType(this.props);
    if (this.state.deliveryNumber) {
      this.props.history.push({
        pathname: ROUTES.DELIVERYANDRETURNENTERDETAIL,
        state: { reference_id: this.state.deliveryNumber }
      });
    } else {
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.PleaseDeliveryNumber)
    }
  }

  handleModalSubmit(event) {
    this.closeModal();
    this.handleSubmit(event);
  }

// ---------------------------------------------------------------------------------------

handleChange(event) {
  let inputVal = event.target.value;

  let updatedInputObj = {
    ...this.state.input,
    [this.state.inputName]: inputVal
  };

  this.setState(
    {
      input: updatedInputObj
    },
    () => {
      this.keyboardRef.keyboard.setInput(inputVal);
    }
  );
}

onChangeAll = inputObj => {
  this.setState({
    input: inputObj,
    deliveryNumber: inputObj.input1
  });
};

onKeyPress = button => {
  /**
   * If you want to handle the shift and caps lock buttons
   */
  if (button === "{shift}" || button === "{lock}") this.handleShift();
  if (button === "{enter}") this.handleSubmit(button);
};

handleShift = () => {
  let layoutName = this.state.layoutName;

  this.setState({
    layoutName: layoutName === "default" ? "shift" : "default"
  });
};

setActiveInput = inputName => {
  this.setState(
    {
      inputName: inputName
    },
    () => { }
  );
};

  render() {
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        <IdleTimerComponent />
        <Header history={this.props.history} />
        <div className="body-container  keyboard-container">
          <section>
            <div className="checkout-process-tabber">
              <div className="checkout-process-wrapper">
                <div className="checkout-process-container white-box container-586">
                  <h4>{LANGCONSTANT[langType].STRINGS.DeliveryNumber}</h4>
                  <form>
                    <div className="form-group">
                      <input
                        type="tel"
                        onFocus={() => this.setActiveInput("input1")}
                        value={this.state.input["input1"] || ""}
                        placeholder={LANGCONSTANT[langType].STRINGS.DeliveryEnter}
                        className="form-control"
                        onChange={event => this.handleChange(event)}
                        autoFocus={true}
                      />
                    </div>
                    <div className="btn-wrapper text-left">
                      <input
                        type="button"
                        className="btn btn-disable"
                        onClick={() => this.back()}
                        value={LANGCONSTANT[langType].BUTTONS.Back}
                      />
                      <input
                        type="submit"
                        value={LANGCONSTANT[langType].BUTTONS.Submit}
                        className="btn btn-submit"
                        onClick={ev => this.handleSubmit(ev)}
                      />
                    </div>
                  </form>
                  <div className="keyboard d-flex">
                    <Keyboard
                      ref={r => (this.keyboardRef = r)}
                      inputName={this.state.inputName}
                      syncInstanceInputs={true}
                      display={{
                        "{bksp}": "backspace",
                        "{enter}": " enter",
                        "{space}": "space"
                      }}
                      layout={{
                        default: ["q w e r t y u i o p {bksp}", "a s d f g h j k l {enter}", "z x c v b n m", "{space}"]
                      }}
                      onChangeAll={inputObj => this.onChangeAll(inputObj)}
                      onKeyPress={button => this.onKeyPress(button)}
                    />
                    <div className="keyboard2-container">
                      <Keyboard
                        syncInstanceInputs={true}
                        inputName={this.state.inputName}
                        baseClass={"keyboard2"}
                        layout={{
                          default: ["1 2 3", "4 5 6", "7 8 9", "0"]
                        }}
                        onChangeAll={inputObj => this.onChangeAll(inputObj)}
                        onKeyPress={button => this.onKeyPress(button)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="progress-bar-bottom">
            <PageProgressComponent
              pageName={LANGCONSTANT[langType].LABELS.DeliveryReturn}
              pageNumber={this.state.pageNumber}
              totalPage={3} EnterDetails
              pageNameList={[LANGCONSTANT[langType].LABELS.ScanGatePass,
              LANGCONSTANT[langType].LABELS.EnterDetails,
              LANGCONSTANT[langType].LABELS.Result]}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

export default connect(mapStateToProps)(DeliveryAndReturnComponent);
