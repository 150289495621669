export const LANDING = '/';
export const CONFIGURATION = '/configuration';
export const CHECKINGATEPASS = '/checkingatepass';
export const APPOINTMENTDETAILS = '/appointmentdetails';
export const DELIVERYDETAILS = '/deliverydetails'
export const TERMSANDCONDITION = '/termsandcondition';
export const CHECKOUTGATEPASS = '/checkoutgatepass';
export const CONFIRMTRAILERDETAILS = '/confirmtrailerdetails';
export const SEALDETAIL = '/sealdetail';
export const SIGNDATA = '/sign';
export const DROPTRAILERS = '/droptrailers';
export const DROPTRAILERRESULT = '/droptrailerresult';
export const DELIVERYANDRETURN = '/deliveryandreturn';
export const DELIVERYANDRETURNENTERDETAIL = '/deliveryanddetails';
export const LANGUAGE = '/selectlanguage';
export const CHECKOUTFLOWSELECTION = '/flowselection';
export const EXITGATE = '/exitgate';
export const EXITINTERMEDIATE = '/traveldetails';
export const INTERNALACCESS = '/internalaccess'
