module.exports = {
  ENG: {
    EXITFLOWVISITTYPES:[
      "Carrier Trailer Pickup",
      "Miscellaneous Checkout",
      "Unloaded Product",
      "UPS/Fedex"
    ],
    LABELS: {
      Admin: "Admin",
      Login: "Login",
      Name: "Name",
      Configure: "Configure",
      Password: "Password",
      UserName: "User name/Email ID",
      CheckIn: "Check In",
      SignHere: "Sign Here",
      Sign: "Sign",
      Device: "Device",
      CheckOut: "Check Out",
      ScanGatePass: "Enter Gate Pass",
      SelectAppointment: "Select Appointment",
      EnterDetails: "Enter Details",
      AcceptTC: "Accept T&C",
      MobileNumber: "Mobile Number",
      PhoneNumber: "Phone Number",
      DriverName: "Driver Name",
      DriverDetails: "Driver Details",
      ConfirmDetails: "Confirm Details",
      PleaseSelectFacility: 'Please Select Facility',
      PleaseSelectLane: 'Please Select Lane',
      SelectFacility: "Select Facility",
      SelectLane: "Select Lane",
      ScanSeal: "Enter Seal",
      ScanSealNumber: "Enter Seal Number",
      SealNumber: "Seal Number",
      SignPrintBill: "Sign & Print Bill",
      SelectAnAppointment: "Select an Appointment",
      AppointmentDetails: "Appointment Details",
      TrailerDetails: "Trailer Details",
      TravelDetails: "Travel Details",
      TrailerNumber: "Trailer Number",
      TrailerType: "Trailer Type",
      TrailerSize: "Trailer Size",
      DestinationLocation: "Destination Location",
      MultipleLocation: "Multiple Locations",
      MultipleDelivery: "Multiple Deliveries",
      AppointmentNumber: "Appointment Number",
      AppointmentDate: "Appointment Date",
      AppointmentTime: "Appointment Time",
      Status: "Status",
      LiftGate: "Lift Gate",
      ScanDockPass: "Enter Dock Door Pass",
      TermsAndConditions: "Terms & Conditions",
      DropEmptyTrailer: "Drop Empty Trailer",
      InternalAccess: "Internal Access",
      DeliveryReturn: "Delivery/Return",
      Erase: "Erase",
      Result: "Result",
      DeliveryNumber: "Delivery Number",
      DropTrailers: "Drop Trailers",
      SelectCarrier: "Carrier",
      English: "English",
      Spanish: "Español",
      SuccessfullyChecked: 'Successfully Checked',
      SelectCheckoutFlow: "Select Checkout Flow",
      LicenseNumber: "License Number",
      ExitGate: 'Exit Gate',
      BillofLead: 'Select Lane to Exit',
      DeliveryDetails: 'Delivery Details',
      PhoneNo: 'Phone #',
      Delivery: 'Delivery',
      Destination: 'Destination',
      CarrierName: 'Carrier Name',
      CustomerName: 'Customer Name',
      DockDoor: 'Yard Location',
      UnloadedCheckout: 'Unloaded Checkout',
      PickupLocation: 'Pickup Location',
      GatePassHeading: 'Loaded Shipment Checkout',
      SelectVisitType: 'Select Visit Type'
    },
    BUTTONS: {
      Select: "Select",
      No: "No",
      Yes: "Yes",
      Ok: "OK",
      Confirm: "Confirm",
      Back: "Back",
      Submit: "Submit",
      Cancel: "Cancel",
      BackToHome: "Back to home",
      Print: "Print",
      NoThanks: "No Thanks",
      Clear: "Clear"
    },
    STRINGS: {
      DropEmptyTrailer: "Do you want to drop an empty trailer?",
      DetailsTrailer: "Enter the details of the trailer you want to drop",
      PleaseEnterTrailer: "Please enter trailer number",
      EnterDetails: "Please enter all the fields",
      PickupNumber: "Enter Pickup Number Manually",
      PickupEnter: "Enter Pickup Number",
      TrailerNumber: "Enter Trailer Number",
      DeliveryNumber: " Enter Delivery Number Manually",
      PleaseDeliveryNumber: "Please enter delivery number",
      DeliveryEnter: "Enter Delivery Number",
      SealNumber: "Enter Seal Number",
      SealNumberManually: "Enter Seal Number Manually",
      ConfirmSeal: "Please verify your seal number before you proceed: ",
      TrailerConditionsOne: " My trailer is in good condition.",
      TrailerConditionsTwo: " My trailer is clean and free from debris.",
      TrailerConditionsThree: ` I am responsible for scaling at the nearest scale to 
      the pick-up location and ensure that my tractor and trailer, 
      when loaded, are at or below the legal gross weight limit for commercial vehicles. 
      If my tractor and trailer are over the limit, I will return to rework the shipment.`,
      TrailerConditionsFour: " My trailer has a door to lock.",
      TrailerConditionsFive: " I will slide back my tandems when necessary.",
      TrailerConditionsSix: ` I am solely responsible for properly restraining my trailer 
      such that it remains in place during loading (e.g. ensuring the dock lock is engaged).`,
      TrailerConditionsSeven: " I am solely responsible for ensuring that my trailer is open prior to docking.",
      TrailerConditionsEight: ` I am solely responsible for placing and securing the seal on the trailer and locking the trailer prior to exiting Niagara’s premises.`,
      TrailerConditionsNine: " I am solely responsible for accurately entering the seal number into the kiosk or app.",
      TrailerConditionsTen: ` I am solely responsible for confirming that the BOL contains the 
      correct seal number and all other necessary information prior to leaving the premises. 
      If the seal number on the BOL does not match the seal on the trailer, 
      I must contact Niagara’s transportation operations team.`,
      TrailerConditionsEleven: ` I agree that I shall not smoke nor engage in any unsafe, 
      illegal or offensive behavior while on the premises.`,
      TrailerConditionsTweleve: ` I agree that Niagara is not responsible for costs, 
      claims and liabilities associated with my failure to comply with the foregoing.`,
      PleaseEnterAccessCode: "Please Enter Access Code",
      EnterAccessCode: "Enter Access Code",
      TooEarly: "You are too early",
      ValidMobile: "Please enter valid mobile number.",
      RateExperience: "Rate your experience",
      CheckTerms: "Please accept terms and conditions before proceeding.",
      CheckSeal: "Please enter seal number.",
      AddSignature: "Please add signature.",
      SignatureTitle: "Please sign below",
      ConfirmNumber: "Please confirm your mobile number",
      SendNotification: "\nWe will send notification on this number.",
      CorrectPickupNumber: "Please enter correct Pickup Number",
      CorrectTrailerNumber: "Please enter correct Trailer Number",
      TooLate: "You are too late",
      TermsCondition: "I agree to the above terms and conditions",
      AgreeTermsConditions: "By continuing you agree to lock door and tie seal",
      DockDoorAllotted: "Dock Door Allotted !!!",
      AccessGranted: "Access Granted!!!",
      GatePassDetails: "Gate pass & details are sent on your mobile number!!!",
      SignatureBoundaries: "The signature should be within the boundaries of this box.",
      SignaturePlaceholder: "Sign Here",
      CheckoutComplete: "Checkout complete, have a safe journey!",
      NextScreen: "Go To Next Screen",
      SelectLanguage: "Select Your Language",
      NoRecordFound: "No record found",
      QrCodeScanFailed: "Invalid Qr code!",
      SelectLaneExit: "Are you checking out a loaded trailer ?",
      FeedBackMsg: "Please collect your paperwork and validate all details before leaving the facility. Please contact shipping office for any issues.",
      EnterBelowDetails: "Enter below details:",
      DoHaveTrailer: "Do you have a trailer ?",
      SelectBOL: "Do you need a BOL?",
      ConfirmCheckOutData: `Please verify your name, phone #, delivery # and destination(City, State)`,
      CheckoutCompleteAccessFailed: "Check out successful, please use call box to exit.",
      CarrierTrailerPickup: "Carrier Trailer Pickup",
      MiscellaneousCheckout: "Miscellaneous Checkout",
      UnloadedProduct: "Unloaded Product",
      UPSFedex: "UPS/Fedex"
    }
  },
  ESP: {
    EXITFLOWVISITTYPES:[
      "Camioneta con remolque portador",
      "Pago misceláneo",
      "Producto descargado",
      "UPS/Fedex"
    ],
    LABELS: {
      Admin: "Admin",
      Login: "iniciar sesión",
      Name: "Nombre",
      Configure: "Configurar",
      SignHere: "Firma aqui",
      Sign: "Firmar",
      Password: "Contrasena",
      UserName: "Usuario/Correo Electronico",
      CheckIn: "Registrarse",
      Device: "Despositivo",
      CheckOut: "Revisa",
      MobileNumber: "Número de móvil",
      PhoneNumber: "Número de teléfono",
      DriverName: "nombre del conductor",
      DriverDetails: "detalles del conductor",
      ScanGatePass: "Escanear pase",
      SelectAppointment: "Elije Cita",
      EnterDetails: "Ingrese Detalles",
      AcceptTC: "Aceptar T&C",
      ConfirmDetails: "Confirmar Detalles",
      PleaseSelectFacility: 'Por favor Elija Planta',
      PleaseSelectLane: 'por favor seleccione el carril',
      SelectFacility: "Elija Entrar sello",
      SelectLane: "Elija Carril",
      ScanSeal: "Entrar sello",
      ScanSealNumber: "Escanear numero de sello",
      SealNumber: "Numero de sello",
      SignPrintBill: "Firma e imprimir BOL",
      SelectAnAppointment: "Elije cita",
      AppointmentDetails: "Detalles de la cita",
      TrailerDetails: "Detalles de la caja",
      TravelDetails: "Detalles de viaje",
      TrailerNumber: "Numero de caja",
      TrailerType: "Tipo de caja",
      TrailerSize: "Tamaño de caja",
      DestinationLocation: "Ubicación de destino",
      MultipleLocation: "Múltiples localizaciones",
      MultipleDelivery: "Entregas múltiples",
      AppointmentNumber: "Número de orden",
      AppointmentDate: "Fecha de cita",
      AppointmentTime: "Hora de cita",
      Status: "estado",
      LiftGate: "puerta trasera",
      ScanDockPass: "Escanear pase para anden",
      TermsAndConditions: "Términos y Condiciones",
      DropEmptyTrailer: "Solo dejar caja",
      InternalAccess: "Acceso Interno",
      DeliveryReturn: "Entrega o Devolución ",
      Erase: "Borrar",
      Result: "Resultado",
      DeliveryNumber: "Numero de Orden",
      DropTrailers: "Solo dejar caja",
      SelectCarrier: "Carrier",
      English: "Inglés",
      Spanish: "Español",
      SuccessfullyChecked: 'Comprobado con éxito',
      SelectCheckoutFlow: 'Seleccionar flujo de pago',
      LicenseNumber: "Número de licencia",
      ExitGate: 'Puerta de salida',
      BillofLead: 'Seleccionar carril para salir',
      DeliveryDetails: 'Detalles de la entrega',
      PhoneNo: 'Número de teléfono',
      Delivery: 'Entrega',
      Destination: 'Destino',
      CarrierName: 'Nombre de la compañía',
      CustomerName: 'Nombre del cliente',
      DockDoor: 'Ubicación del patio',
      UnloadedCheckout: 'Pago descargado',
      PickupLocation: 'Lugar de recogida',
      GatePassHeading: 'Pago de envío cargado',
      SelectVisitType: 'Seleccione el tipo de visita'
    },
    BUTTONS: {
      Select: "Elija",
      No: "No",
      Yes: "Si",
      Ok: "OK",
      Confirm: "Confirmar",
      Back: "Regresar",
      Submit: "Enviar",
      Cancel: "Cancelar",
      BackToHome: "Regreso a inicio",
      Print: "Imprimir",
      NoThanks: "No gracias",
      Clear: "Claro"
    },
    STRINGS: {
      DropEmptyTrailer: "Quieres dejar una caja vacía?",
      DetailsTrailer: "Ingrese los detalles de la caja a dejar",
      EnterDetails: "Por favor ingrese todos los campos",
      PleaseEnterTrailer: "Por favor ingrese el número de tráiler",
      PickupNumber: "Ingrese el numero de orden",
      PickupEnter: "Ingrese el numero de orden",
      TrailerNumber: "Ingrese el numero de caja",
      DeliveryNumber: "Ingrese el numero de entrega",
      PleaseDeliveryNumber: "Por favor introduzca el número de entrega",
      DeliveryEnter: "numero de entrega",
      SealNumber: "Ingrese el sello",
      SealNumberManually: "Introduzca el número de sello manualmente",
      ConfirmSeal: "Por favor verifique su número de sello antes de continuar: ",
      TrailerConditionsOne: " Mi remolque está en buenas condiciones.",
      TrailerConditionsTwo: " Mi remolque está limpio y libre de residuos y olores.",
      TrailerConditionsThree: ` Soy responsable de escalar en la escala más cercana a la 
      ubicación de recogida y me aseguro de que mi tractor y mi remolque, cuando están 
      cargados, se encuentren en o por debajo del límite legal de peso bruto para vehículos 
      comerciales. Si mi tractor y mi remolque están por encima del límite, 
      Volveré a retrabajar el envío.`,
      TrailerConditionsFour: " Mi trailer tiene una puerta que cierra.",
      TrailerConditionsFive: " Volveré a deslizar mis tándems cuando sea necesario.",
      TrailerConditionsSix: ` Soy el único responsable de restringir adecuadamente mi remolque 
      para que permanezca en su lugar durante la carga (por ejemplo, asegurarme de que el
         bloqueo del muelle esté activado).`,
      TrailerConditionsSeven: " Soy el único responsable de garantizar que mi remolque esté abierto antes de su acoplamiento.",
      TrailerConditionsEight: ` Soy el único responsable de colocar y asegurar el sello en el 
      remolque y de bloquearlo antes de salir de las instalaciones de Niagara.`,
      TrailerConditionsNine: " Soy el único responsable de ingresar con precisión el número del sello en el quiosco o la aplicación.",
      TrailerConditionsTen: ` Soy el único responsable de confirmar que el BOL contiene el número 
      de sello correcto y toda la información necesaria antes de salir de las instalaciones. 
      Si el número de sello en el BOL no coincide con el sello del remolque, debo comunicarme 
      con el equipo de operaciones de transporte de Niagara.`,
      TrailerConditionsEleven: ` Estoy de acuerdo en no fumar ni involucrarme en ningún comportamiento 
      inseguro, ilegal u ofensivo mientras esté en las instalaciones.`,
      TrailerConditionsTweleve: ` Estoy de acuerdo en que Niagara no es responsable de los costos, 
      reclamaciones y responsabilidades asociadas con mi incumplimiento de lo anterior.`,
      PleaseEnterAccessCode: "Por favor Ingrese codigo de acceso ",
      EnterAccessCode: "Ingrese codigo de acceso",
      TooEarly: "Es demasiado temprano",
      ValidMobile: "Por favor ingrese un número de teléfono móvil válido.",
      TermsCondition: "Acepto los términos y condiciones",
      RateExperience: "Califica tu experiencia",
      AddSignature: "Por favor agregue la firma.",
      SignatureTitle: "Por favor firme abajo",
      CorrectPickupNumber: "Ingrese numero de ordern correcto",
      CorrectTrailerNumber: "Ingrese numero de caja correcta",
      ConfirmNumber: "Por favor confirme su número de móvil",
      SendNotification: "\nLe enviaremos una notificación a este número.",
      TooLate: "Es demasiado tarde",
      CheckSeal: "Por favor ingrese el número de sello.",
      CheckTerms: "Por favor acepte los términos y condiciones antes de continuar.",
      AgreeTermsConditions: "Al continuar aceptas bloquear la puerta y atar la junta.",
      DockDoorAllotted: "Anden asignado!!!",
      AccessGranted: "Acceso permitido!!!",
      GatePassDetails: "Pase de acceso y detalles enviados a su teléfono móvil!!!",
      SignatureBoundaries: "La firma debe estar dentro de los límites del campo.",
      SignaturePlaceholder: "Firma aqui",
      CheckoutComplete: "Checkout exitoso, buen viaje!",
      NextScreen: "Ir a la siguiente pantalla",
      SelectLanguage: "Elige tu idioma",
      NoRecordFound: "ningún record fue encontrado",
      QrCodeScanFailed: "Código Qr inválido!",
      SelectLaneExit: "Estás revisando el trailer cargado ?",
      FeedBackMsg: "Reúna su documentación y valide todos los detalles antes de abandonar la instalación. Póngase en contacto con la oficina de envíos para cualquier problema.",
      EnterBelowDetails: "Ingrese los detalles a continuación:",
      DoHaveTrailer: "¿Tienes un trailer?",
      SelectBOL: "Necesitas un BOL?",
      ConfirmCheckOutData: `Verifique su nombre, número de teléfono, número de entrega y destino (ciudad, estado)`,
      CheckoutCompleteAccessFailed:"El check out se realizó correctamente, utilice el cuadro de llamada para salir", 
      CarrierTrailerPickup: "Camioneta con remolque portador",
      MiscellaneousCheckout: "Pago misceláneo",
      UnloadedProduct: "Producto descargado",
      UPSFedex: "UPS/Fedex"
    }
  }
};
