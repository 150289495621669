import * as TYPE from '../actions/actionType';

export default function Reducer(state = {}, action) {

  switch (action.type) {
    case TYPE.CHECKINDATA:
      return { ...state, checkinData: action.data }

    case TYPE.CHECKOUTDATA:
      return { ...state, checkoutData: action.data }

    case TYPE.SHIPMENTDATA:
      return { ...state, shipmentData: action.data }

    case TYPE.MASTERDATA:
      return { ...state, masterData: action.data }

    case TYPE.DELIVERYDATA:
      return { ...state, deliveryData: action.data }

    case TYPE.DROPTRAILERDATA:
      return { ...state, dropTrailerData: action.data }

    case TYPE.DELIVERYRETURNDATA:
      return { ...state, deliveryReturnData: action.data }

    case TYPE.CHECKOUTSHIPMENTDATA:
      return { ...state, checkoutShipmentData: action.data }

    case TYPE.LANGUAGEDATA:
      return { ...state, languageData: action.data }

    case TYPE.PAGEDATA:
      return { ...state, pageData: action.data }

    case TYPE.GUARDDATA:
      return { ...state, guardData: action.data }

    default:
      return state;
  }
}
