import { connect } from 'react-redux';
import * as guardAction from '../redux/actions/guardAction';
import propTypes from 'prop-types';
// import { Redirect } from "react-router";
import * as ROUTES from '../constants/routes';
import { bindActionCreators } from 'redux';

class LanguageUtils {
    static getLangType(props) {
        return props.languageData ? props.languageData.langType : 'ENG';
    }

    static logout() {
        this.props.history.push(ROUTES.LANDING);
    }

}

function mapStateToProps(state) {
    const data = state.data;
    return data;
}

function mapDispatchToProps(dispatch) {
    return {
        guardAction: bindActionCreators(guardAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LanguageUtils);