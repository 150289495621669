export const USERNAME_ERRORS = {
    blankFieldError: "Please enter user name"
};

export const PASSWORD_ERRORS = {
    blankFieldError: "Please enter password",
};

export const DRIVER_ERRORS = {
    blankFieldError: "Please enter driver name",
};

export const EXT_ERRORS = {
    blankFieldError: "Please enter country code",
    numberFieldError: "Please enter valid country code"
};

export const LANGUAGE_ERRORS = "Please select extension from dropdown";

export const TNC_ERRORS = "Please agree to terms and conditions";

export const OTP_ERRORS = {
    blankFieldError: "Please enter otp",
    maxLengthError: "Enter atleast 6 characters",
    numberFieldError: "Invalid otp"
};

export const DROP_TRAILER_ERROR = {
    selectOneOptionError: "Please select atleast one option",
    trailerNumberError: "Please enter the correct trailer number",
    blankFieldError: "Please enter trailer number"
};

export const NAME_ERRORS = {
    blankFieldError: "Please enter name",
    maxLengthError: "Name must not be less than 3 characters",
    nameFieldError: "Please enter the valid name"
};

export const LICENSE_ERRORS = {
    blankFieldError: "Please enter license number",
    numberFieldError: "Please enter valid license number"
};

export const VALUE_ERROR = {
    blankFieldError: "Please enter value",
    numberFieldError: "Please enter a valid number"
};