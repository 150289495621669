import React, { Component } from "react";
import { connect } from "react-redux";
import * as ROUTES from "../../constants/routes";
import PageProgressComponent from "../../common/PageProgressComponent";
import Header from "../../common/Header";
import * as LANGCONSTANT from "../../constants/language";
import LanguageUtils from "../../utils/LanguageUtils";
import IdleTimerComponent from "../../common/idleCheck/IdleTimerComponent";
import Modal from "react-modal";
import AlertModal from "../../common/modals/AlertModal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class CheckinAppointmentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointmentData: [],
      pageNumber: 2,
      modalIsOpen: false,
      noTrailerModalIsOpen: false,
      index: 0
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async componentWillMount() {
    await this.setData();
  }

  openModal(index) {
    this.setState({
      modalIsOpen: true,
      index: index
    });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#6495ed";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openNoTrailerModal(index) {
    this.setState({
      noTrailerModalIsOpen: true,
      index: index
    });
  }

  closeNoTrailerModal() {
    this.setState({ noTrailerModalIsOpen: false });
  }

  async setData() {
    await this.setState({
      appointmentData: this.props.masterData ? this.props.masterData.delivery : []
    });
  }
  handleData(index) {
    if (this.state.appointmentData[index][0].visit_type === "PL") {
      if (
        // this.state.appointmentData[index][0].trailer_details.trailer_status == null ||
        !!this.state.appointmentData[index][0].trailer_details.trailer_number
      ) {
        // open confirmation modal Do you want to drop an empty trailer
        this.openModal(index);
      } else {
        this.openNoTrailerModal(index);
      }
    } else {
      this.props.history.push({
        pathname: ROUTES.DELIVERYDETAILS,
        state: {
          detail: ROUTES.APPOINTMENTDETAILS,
          reference_id: this.props.location.state.reference_id,
          index: index,
          flag: true,
          didQueryReturnTrailerNumber: this.props.location.state.didQueryReturnTrailerNumber
        }
      });
    }
  }

  handleModalNoTrailer() {
    this.closeNoTrailerModal();
    // AlertModal.showAlert('Please contact shipping office.');
    // setTimeout(() => {
    //   AlertModal.hideAlert();
    //   this.props.history.push(ROUTES.CHECKINGATEPASS);
    // }, 10000);
    this.props.history.push({
      pathname: ROUTES.DELIVERYDETAILS,
      state: {
        detail: ROUTES.APPOINTMENTDETAILS,
        reference_id: this.props.location.state.reference_id,
        index: this.state.index,
        flag: false,
        didQueryReturnTrailerNumber: this.props.location.state.didQueryReturnTrailerNumber
      }
    });
  }

  handleModalYesTrailer() {
    this.closeNoTrailerModal();
    this.props.history.push({
      pathname: ROUTES.DELIVERYDETAILS,
      state: {
        detail: ROUTES.APPOINTMENTDETAILS,
        reference_id: this.props.location.state.reference_id,
        index: this.state.index,
        flag: true,
        didQueryReturnTrailerNumber: this.props.location.state.didQueryReturnTrailerNumber
      }
    });
  }

  handelModalNo() {
    this.props.history.push({
      pathname: ROUTES.DELIVERYDETAILS,
      state: {
        detail: ROUTES.APPOINTMENTDETAILS,
        reference_id: this.props.location.state.reference_id,
        index: this.state.index,
        flag: true,
        didQueryReturnTrailerNumber: this.props.location.state.didQueryReturnTrailerNumber
      }
    });
  }

  handleModalSubmit() {
    this.props.history.push({
      pathname: ROUTES.DROPTRAILERS,
      state: {
        checkFlow: true,
        reference_id: this.props.location.state.reference_id,
        index: this.state.index,
        didQueryReturnTrailerNumber: this.props.location.state.didQueryReturnTrailerNumber
      }
    });
  }

  render() {
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        <IdleTimerComponent />
        <Header history={this.props.history} />
        <div className="body-container">
          <section>
            <div className="checkout-process-tabber">
              <div className="checkout-process-wrapper">
                <div className="checkout-process-container container-890 checkin-appointment-container">
                  <h3 className="text-center" style={{ fontWeight: "600" }}>
                    {LANGCONSTANT[langType].LABELS.SelectAnAppointment}
                  </h3>
                  {this.state.appointmentData.map((deliveryData, index) => {
                    return (
                      <div className="white-box confirmation-detail" key={deliveryData[0].id}>
                        <h4>{LANGCONSTANT[langType].LABELS.AppointmentDetails}</h4>
                        <div className="d-flex justify-content-between">
                          <div className="confirmation-trailer-detail">
                            <ul>
                              <li>
                                <strong>{LANGCONSTANT[langType].LABELS.DestinationLocation}:</strong>
                                {/* {
                                  deliveryData.length === 1 ? (
                                    deliveryData.map((data) => <span key={deliveryData[0].id}>{data.address}</span>)
                                  ) : (
                                      <a href="javaScript:void(0)" onClick={() => this.openModal(0, deliveryData)}>
                                        Multiple Locations
                                      </a>
                                    )} */}
                                  <span>{deliveryData[0].address}</span>
                              </li>
                              <li>
                                <strong>{LANGCONSTANT[langType].LABELS.AppointmentDate}:</strong>
                                <span>{deliveryData[0].pickup_app_time}</span>
                              </li>
                              <li>
                                <strong>{LANGCONSTANT[langType].LABELS.DeliveryNumber}:</strong>
                                <span>{deliveryData[0].id}</span>
                              </li>
                            </ul>
                          </div>
                          <div className="confirmation-appointment-detail">
                            <ul key={index}>
                              <li>
                                <strong>{LANGCONSTANT[langType].LABELS.AppointmentNumber}:</strong>
                                <span>{deliveryData[0].trip_id}</span>
                              </li>
                              <li>
                                <strong>{LANGCONSTANT[langType].LABELS.Status}:</strong>
                                <span>{deliveryData[0].trailer_details.trailer_status}</span>
                              </li>
                              <li>
                                <input
                                  type="button"
                                  className="btn btn-submit"
                                  value={LANGCONSTANT[langType].BUTTONS.Select}
                                  onClick={() => this.handleData(index)}
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
          <div className="progress-bar-bottom">
            <PageProgressComponent
              pageName={LANGCONSTANT[langType].LABELS.CheckIn}
              pageNumber={this.state.pageNumber}
              totalPage={4}
              pageNameList={[
                LANGCONSTANT[langType].LABELS.ScanGatePass,
                LANGCONSTANT[langType].LABELS.SelectAppointment,
                LANGCONSTANT[langType].LABELS.EnterDetails,
                LANGCONSTANT[langType].LABELS.AcceptTC
              ]}
            />
          </div>
        </div>
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal">
            <div className="modal confirmation-modal-dialog">
              <div className="delivery-access-body">
                <h5 ref={subtitle => (this.subtitle = subtitle)}>{LANGCONSTANT[langType].STRINGS.DropEmptyTrailer}</h5>
                <div className="d-flex">
                  <button className="btn btn-disable" onClick={() => this.handelModalNo()}>
                    {LANGCONSTANT[langType].BUTTONS.No}
                  </button>
                  <button className="btn btn-submit" onClick={() => this.handleModalSubmit()}>
                    {LANGCONSTANT[langType].BUTTONS.Yes}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={this.state.noTrailerModalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeNoTrailerModal}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal">
            <div className="modal confirmation-modal-dialog">
              <div className="delivery-access-body">
                <h5 ref={subtitle => (this.subtitle = subtitle)}>{LANGCONSTANT[langType].STRINGS.DoHaveTrailer}</h5>
                <div className="d-flex">
                  <button className="btn btn-disable" onClick={() => this.handleModalNoTrailer()}>
                    {LANGCONSTANT[langType].BUTTONS.No}
                  </button>
                  <button className="btn btn-submit" onClick={() => this.handleModalYesTrailer()}>
                    {LANGCONSTANT[langType].BUTTONS.Yes}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

export default connect(mapStateToProps)(CheckinAppointmentComponent);
