import React, { Component } from "react";
import * as ROUTES from "../../constants/routes";
import PageProgressComponent from "../../common/PageProgressComponent";
import { connect } from "react-redux";
import Header from "../../common/Header";
import * as LANGCONSTANT from "../../constants/language";
import IdleTimerComponent from "../../common/idleCheck/IdleTimerComponent";
import LanguageUtils from "../../utils/LanguageUtils";
import Modal from "react-modal";
import { CONFIG } from "../../configuration/config";
import { CallApi } from "../../services/service";
import StarRatingComponent from "react-star-rating-component";
import { spinnerName } from "../../constants/data";
import { spinnerService } from "../../services/spinner.service";
import AlertModal from "../../common/modals/AlertModal";
import { getData } from "../../containers/storageHandler";
import { ConfirmationModal } from '../../common/modals/ConfirmationModal';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    modalIsOpen: false,
    type: -1,
  }
};
class ConfirmTrailerDetailCompoent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trailerDetail: [],
      data: [],
      pageNumber: 3,
      pageNameList: [
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ExitGate,
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ConfirmDetails,
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanSeal,
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Sign
      ],
      checkoutObj: {},
      configurationData: getData("configureData"),
      feedbackModalIsOpen: false,
      rating: 0,
      feedBackText: "",
      confirmModalIsOpen: false,
      index: null
    };
    ConfirmTrailerDetailCompoent._singletonRef = this;
  }

  async componentWillMount() {
    await this.setData();
  }

  onStarClick(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }

  openFeedbackModal() {
    this.setState({ feedbackModalIsOpen: true });
  }

  closeFeedbackModal() {
    this.setState({ feedbackModalIsOpen: false });
  }

  submitFeedback() {
    this.setState({ feedbackModalIsOpen: false });
    const feedBackObj = {
      profile_id: this.state.configurationData ? this.state.configurationData.facilityId : "",
      comments: this.state.feedBackText,
      ratings: this.state.rating
    };
    if (feedBackObj.profile_id !== "") {
      const langType = LanguageUtils.getLangType(this.props);
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      CallApi("POST", CONFIG.feedBack, {}, feedBackObj, langType, "returnFeedback", this);
    }
  }

  returnFeedback = response => {
    if (response.success === true) {
      this.props.history.push(ROUTES.EXITGATE);
    } else {
      AlertModal.showAlert(response.message);
    }
  };

  cancel() {
    this.closeModal();
    this.props.history.push(ROUTES.EXITGATE);
  }

  openModal(type, data) {
    this.setState({ modalIsOpen: true, type: type, data: data });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#6495ed";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  async setData() {
    await this.setState({
      trailerDetail: this.props.deliveryData ? this.props.deliveryData.delivery : []
    });
  }
  back() {
    this.props.history.goBack();
  }

  confirm(index = 0) {
    let self = ConfirmTrailerDetailCompoent._singletonRef;
    self.toggleConfirmModal();
    const langType = 'ENG';
    if (getData('isBOL') === 'Yes') {
      self.props.history.push({
        pathname: ROUTES.SEALDETAIL,
        state: {
          index: index
        }
      });
    } else if (getData('isBOL') === 'No') {
      const checkoutObj = {
        reference_id: self.props.guardData ? self.props.guardData.trailerNumber : "",
        org_id: self.props.checkoutData ? self.props.checkoutData.facilityId : "",
        app_no: self.props.deliveryData ? (self.props.deliveryData.delivery[index][0].trip_id || self.props.deliveryData.delivery[index][0].id || "") : "",
        seal_no: '',
        language: langType === 'ESP' ? 'es' : "en",
        lane_no: self.props.checkoutData ? self.props.checkoutData.laneNo : "",
        carrier_name: self.props.deliveryData ? self.props.deliveryData.delivery[index][0].carrier_name : "",
        driver_name: self.props.deliveryData ? self.props.deliveryData.delivery[index][0].trailer_details.driver_name : "",
        phone_no: self.props.deliveryData ? self.props.deliveryData.delivery[index][0].trailer_details.driver_phone : "",
        base64: '',
        trailer_number: self.props.deliveryData ? self.props.deliveryData.delivery[index][0].trailer_details.trailer_number : "",
        visit_type: self.props.deliveryData ? self.props.deliveryData.delivery[index][0].visit_type : ""
      };
      self.setState({ checkoutObj });
      if (checkoutObj.org_id !== "") {
        spinnerService.show(spinnerName.LOGIN_SPINNER);
        if (checkoutObj.org_id && checkoutObj.lane_no) {
          CallApi("POST", CONFIG.orderCheckout, {}, checkoutObj, langType, "orderCheckoutCallback", self);
        }
      } else {
        spinnerService.hide(spinnerName.LOGIN_SPINNER);
        AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.EnterDetails);
      }
    }
  }

  exitGateCallback = (response) => {
    const language = LanguageUtils.getLangType(this.props);
    if (response.success == true) {
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      CallApi("POST", CONFIG.orderCheckout, {}, this.state.checkoutObj, language, "returnOrderCheckout", this);
    }
  };

  orderCheckoutCallback = (response) => {
    const language = LanguageUtils.getLangType(this.props);
    let index = getData('laneIndex') || 1;
    const exitObj = {
      language: language === 'ESP' ? 'es' : "en",
      reference_id: "",
      org_id: getData('configureData').facilityId ? getData('configureData').facilityId : "",
      lane_no: index
    };
    
    if (response.success == true && response.result.status === true) {
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      CallApi("POST", CONFIG.exitGate, {}, exitObj, language, "returnInternalAccess", this);
    }
  };

  returnOrderCheckout = response => {
    if (response.success === true) {
      if (response.result.status === true) {
        this.openFeedbackModal();
      } else {
        AlertModal.showAlert(response.result.message);
      }
    }
  };

  returnInternalAccess = response =>{
    const language = LanguageUtils.getLangType(this.props);
    if (response.success === true) {
      if (response.result.status === "TRUE") {
        this.openFeedbackModal();
      } else {
        AlertModal.showAlert(response.result.message);
      }
    }
  }

  toggleConfirmModal = (index = null) => {
    this.setState({
      confirmModalIsOpen: !this.state.confirmModalIsOpen
    }, () => {
      if (this.state.confirmModalIsOpen) {
        this.setIndex(index)
      }
    })
  }

  setIndex = (index) => {
    this.setState({
      index: index
    })
  }

  render() {
    const { rating } = this.state;
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        <IdleTimerComponent />
        <Header history={this.props.history} />
        <div className="body-container">
          {!(getData('isBOL') === 'No') ?
            <section>
              <div className="checkout-process-tabber">
                <div className="checkout-process-wrapper">
                  <div className="checkout-process-container container-890">
                    {this.state.trailerDetail.map((deliveryData, index) => {
                      return (
                        <div className="white-box confirmation-detail d-flex flex-wrap" key={index}>
                          <div className="confirmation-trailer-detail">
                            <h4>{LANGCONSTANT[langType].LABELS.TrailerDetails}</h4>
                            <ul>
                              <li>
                                <strong>{LANGCONSTANT[langType].LABELS.TrailerNumber}</strong><span>{
                                  deliveryData[0].trailer_details
                                    .trailer_number}</span>
                              </li>
                              <li>
                                <strong>{LANGCONSTANT[langType].LABELS.CarrierName}</strong><span>{deliveryData[0].carrier_name}</span>
                              </li>
                              <h4>{LANGCONSTANT[langType].LABELS.DriverDetails}</h4>
                              <li>
                                <strong>{LANGCONSTANT[langType].LABELS.Name}</strong><span>{deliveryData[0].trailer_details.driver_name}</span>
                              </li>
                              {deliveryData[0].trailer_details.driver_phone &&
                                <li>
                                  <strong>{LANGCONSTANT[langType].LABELS.PhoneNo}</strong><span>{deliveryData[0].trailer_details.driver_phone}</span>
                                </li>
                              }
                              {deliveryData[0].trailer_details.dock_door &&
                                <li>
                                  <strong>{LANGCONSTANT[langType].LABELS.DockDoor}</strong><span>{deliveryData[0].trailer_details.dock_door}</span>
                                </li>
                              }
                            </ul>
                          </div>
                          {(deliveryData[0].visit_type != 'LU' && (deliveryData[0].id || deliveryData[0].address ||
                            deliveryData[0].trip_id || deliveryData[0].pickup_app_time || deliveryData[0].customer_name)) &&
                            <div className="confirmation-appointment-detail">
                              <h4>{LANGCONSTANT[langType].LABELS.AppointmentDetails}</h4>
                              <ul key={index}>
                                {!(getData('isBOL') === 'No') &&
                                  <li>
                                    <strong>{LANGCONSTANT[langType].LABELS.DestinationLocation}</strong>
                                    {
                                      // deliveryData.length === 1 ? (
                                      // deliveryData.map((data) => data.address)
                                      deliveryData[0].address
                                      // ) : (
                                      //     <a href="javaScript:void(0)" onClick={() => this.openModal(0, deliveryData)}>
                                      //       {LANGCONSTANT[langType].LABELS.MultipleLocation}
                                      //     </a>
                                      //   )
                                    }
                                  </li>
                                }
                                {(deliveryData[0].trip_id || deliveryData[0].id) &&
                                  <li>
                                    <strong>{LANGCONSTANT[langType].LABELS.AppointmentNumber}</strong><span>{deliveryData[0].trip_id || deliveryData[0].id}</span>
                                  </li>
                                }
                                {!(getData('isBOL') === 'No') &&
                                  <li>
                                    <strong>{LANGCONSTANT[langType].LABELS.AppointmentTime}</strong><span>{deliveryData[0].pickup_app_time}</span>
                                  </li>
                                }
                                {(((deliveryData.length && deliveryData.id || deliveryData.length > 1) && getData('isBOL') === 'No') || getData('isBOL') !== 'No') &&
                                  <li>
                                    <strong>{LANGCONSTANT[langType].LABELS.DeliveryNumber}</strong>
                                    {
                                      // deliveryData.length === 1 ? (
                                      // deliveryData.map((data) => data.id)
                                      deliveryData[0].id
                                      // ) : (
                                      //   <a href="javaScript:void(0)" onClick={() => this.openModal(1, deliveryData)}>
                                      //     {LANGCONSTANT[langType].LABELS.MultipleDelivery}
                                      //   </a>
                                      // )
                                    }
                                  </li>}
                                {deliveryData[0].customer_name &&
                                  <li>
                                    <strong>{LANGCONSTANT[langType].LABELS.CustomerName}</strong><span>{deliveryData[0].customer_name}</span>
                                  </li>
                                }
                              </ul>
                            </div>
                          }
                          <div className="btn-wrapper text-right">
                            <input type='button' className="btn btn-disable" value={LANGCONSTANT[langType].BUTTONS.Back} onClick={() => this.back()} />
                            <input type='button' className="btn btn-submit" value={LANGCONSTANT[langType].BUTTONS.Confirm} onClick={() => this.toggleConfirmModal(index)} />
                          </div>
                        </div>
                      )
                    }
                    )}
                  </div>
                </div>
              </div>
            </section> :
            <section>
              <div className="checkout-process-tabber">
                <div className="checkout-process-wrapper">
                  <div className="checkout-process-container container-890">
                    {this.state.trailerDetail.map((deliveryData, index) => {
                      return (
                        <div className="white-box confirmation-detail d-flex flex-wrap" key={index}>
                          <div className="confirmation-trailer-detail">
                            <h4>{LANGCONSTANT[langType].LABELS.TrailerDetails}</h4>
                            <ul>
                              <li>
                                <strong>{LANGCONSTANT[langType].LABELS.TrailerNumber}</strong><span>{
                                  deliveryData[0].trailer_details
                                    .trailer_number}</span>
                              </li>
                              {!!deliveryData[0].address && <li>
                                <strong>{LANGCONSTANT[langType].LABELS.PickupLocation}</strong><span>{deliveryData[0].address}</span>
                              </li>}
                              <li>
                                <strong>{LANGCONSTANT[langType].LABELS.CarrierName}</strong><span>{deliveryData[0].carrier_name}</span>
                              </li>
                              {deliveryData[0].trailer_details.dock_door &&
                                <li>
                                  <strong>{LANGCONSTANT[langType].LABELS.PickupLocation}</strong><span>{deliveryData[0].trailer_details.dock_door}</span>
                                </li>
                              }
                            </ul>
                          </div>

                          <div className="confirmation-appointment-detail">
                            <h4>{LANGCONSTANT[langType].LABELS.DriverDetails}</h4>
                            <ul key={index}>
                              <li>
                                <strong>{LANGCONSTANT[langType].LABELS.Name}</strong><span>{deliveryData[0].trailer_details.driver_name}</span>
                              </li>
                              {deliveryData[0].trailer_details.driver_phone &&
                                <li>
                                  <strong>{LANGCONSTANT[langType].LABELS.PhoneNo}</strong><span>{deliveryData[0].trailer_details.driver_phone}</span>
                                </li>
                              }
                            </ul>
                          </div>
                          <div className="btn-wrapper text-right">
                            <input type='button' className="btn btn-disable" value={LANGCONSTANT[langType].BUTTONS.Back} onClick={() => this.back()} />
                            <input type='button' className="btn btn-submit" value={LANGCONSTANT[langType].BUTTONS.Confirm} onClick={() => this.toggleConfirmModal(index)} />
                          </div>
                        </div>
                      )
                    }
                    )}
                  </div>
                </div>
              </div>
            </section>}
          <div className="progress-bar-bottom">
            {!(getData('isBOL') === 'No') ?
              <PageProgressComponent
                pageName={LANGCONSTANT[langType].LABELS.CheckOut}
                pageNumber={this.state.pageNumber}
                totalPage={5}
                pageNameList={
                  [
                    LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ExitGate,
                    LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
                    LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ConfirmDetails,
                    LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanSeal,
                    LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Sign
                  ]
                }
              />
              :
              <PageProgressComponent
                pageName={LANGCONSTANT[langType].LABELS.CheckOut}
                pageNumber={this.state.pageNumber}
                totalPage={3}
                pageNameList={
                  [
                    LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ExitGate,
                    LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
                    LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ConfirmDetails,
                  ]
                }
              />
            }
          </div>
        </div>
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={() => this.afterOpenModal()}
            onRequestClose={() => this.closeModal()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal">
            <div className="modal" style={{ minWidth: "400px", width: "unset" }}>
              <center>
                <h2 ref={subtitle => (this.subtitle = subtitle)}>{this.state.type === 0 ? "Locations" : "Delivery Id"}</h2>
                {this.state.data.map((data, index) => (
                  <p key={index}>{this.state.type === 0 ? data.address : data.id}</p>
                ))}
                <button onClick={() => this.closeModal()}>{LANGCONSTANT[langType].BUTTONS.Ok}</button>
              </center>
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={this.state.feedbackModalIsOpen}
            onAfterOpen={() => this.afterOpenModal()}
            onRequestClose={() => this.closeFeedbackModal()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal">
            <div className="modal feedback-modal text-center">
              <h2 ref={subtitle => (this.subtitle = subtitle)}>{LANGCONSTANT[langType].STRINGS.CheckoutComplete}</h2>
              <p className="feedback-msg">
                {LANGCONSTANT[langType].STRINGS.FeedBackMsg}
              </p>
              <p>
                <b>{LANGCONSTANT[langType].STRINGS.RateExperience}</b>
              </p>
              <div className="rating-stars">
                <StarRatingComponent
                  name="rate1"
                  starCount={5}
                  emptyStarColor={"#ffffff"}
                  value={rating}
                  onStarClick={this.onStarClick.bind(this)}
                />
              </div>
              <div className="btn-alignment">
                <button onClick={() => this.cancel()}>{LANGCONSTANT[langType].BUTTONS.Cancel}</button>
                <button onClick={() => this.submitFeedback()}>{LANGCONSTANT[langType].BUTTONS.Submit}</button>
              </div>
            </div>
          </Modal>
        </div>
        {(this.props.deliveryData && this.state.index != null) &&
          <ConfirmationModal alertModalIsOpen={this.state.confirmModalIsOpen} confirm={this.confirm}
            closeAlertModal={this.toggleConfirmModal} index={this.state.index}
            name={this.props.deliveryData.delivery[this.state.index][0].trailer_details.driver_name}
            phone={this.props.deliveryData.delivery[this.state.index][0].trailer_details.driver_phone}
            delivery={this.props.deliveryData.delivery[this.state.index][0].id}
            destination={this.props.deliveryData.delivery[this.state.index][0].address} langType={langType} />}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const data = state.data;
  return data;
}

export default connect(mapStateToProps)(ConfirmTrailerDetailCompoent);
