import React, { Component } from 'react';
import { CONFIG } from '../../configuration/config';
import { CallApi } from '../../services/service';
import * as ROUTES from '../../constants/routes';
import { connect } from 'react-redux';
import * as dataAction from '../../redux/actions/deliveryDataAction';
import propTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import PageProgressComponent from '../../common/PageProgressComponent';
import { spinnerName } from '../../constants/data';
import { spinnerService } from '../../services/spinner.service';
import Header from '../../common/Header';
import * as LANGCONSTANT from '../../constants/language';
import LanguageUtils from '../../utils/LanguageUtils';
import IdleTimerComponent from '../../common/idleCheck/IdleTimerComponent';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import AlertModal from "../../common/modals/AlertModal";
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};
class CheckinDeliveryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carrierList: '',
      trailerTypeList: null,
      trailerSizeList: [],
      optionList: ['Yes', 'No'],
      liftGate: 'no',
      trailerType: 'Dry Van',
      trailerId: '',
      trailerSize: '',
      driverName: '',
      mobileNumber: '',
      trailerNumber: '',
      prePath: '',
      pageNumber: 3,
      modalMessage: '',
      verifyModalIsOpen: false,
      layoutName: "default",
      inputName: "input1",
      input: {},
      vistType: ''
    };

    this.openVerifyModal = this.openVerifyModal.bind(this);
    this.afterVerifyOpenModal = this.afterVerifyOpenModal.bind(this);
    this.closeVerifyModal = this.closeVerifyModal.bind(this);
  }

  componentDidMount() {
    this.getCarrierInfo();
    let index = this.props.location.state.index || 0;
    this.setState({
      driverName: this.props.masterData ? this.props.masterData.delivery[index][0].trailer_details.driver_name : '',
      mobileNumber: this.props.masterData ? this.props.masterData.delivery[index][0].trailer_details.driver_phone : '',
      trailerNumber: this.props.masterData ? this.props.masterData.delivery[index][0].trailer_details.trailer_number : '',
      vistType: this.props.masterData ? this.props.masterData.delivery[index][0].visit_type : ''
    }, () => { }
    );
  }

  openVerifyModal() {
    this.setState({
      verifyModalIsOpen: true,
    });
  }

  closeVerifyModal() {
    this.setState({
      verifyModalIsOpen: false
    });
  }

  afterVerifyOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#000000';
  }

  getCarrierInfo() {
    const language = this.props.languageData ? this.props.languageData.langType : 'ENG';
    spinnerService.show(spinnerName.LOGIN_SPINNER);
    CallApi('GET', CONFIG.getCarrierInfo, {}, {}, language, "returnGetCarrierInfo", this);
  }

  returnGetCarrierInfo = response => {
    const langType = LanguageUtils.getLangType(this.props);
    if (response.success) {
      spinnerService.hide(spinnerName.LOGIN_SPINNER);

      // Remove it after api changes
      response.result.trailer_type['ENG'][1].trailerId = "2";
      response.result.trailer_type['ESP'][1].trailerId = "2";
      this.setState({
        carrierList: response.result.carriers,
        trailerTypeList: response.result.trailer_type,
        trailerSizeList: response.result.trailer_size
      });
      if (response.result.trailer_type && response.result.trailer_type[langType]) {
        this.setState({
          trailerId: response.result.trailer_type[langType][1]['trailerId'],
        });
      }
      if(response.result.trailer_size){
        this.setState({
            trailerSize: response.result.trailer_size[2].value
        })
      }
    }
  };

  handleDataChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleMobileChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  checkNumber(event) {
    if (!(event.charCode > 47 && event.charCode <= 57)) {
      event.preventDefault();
    }
  }

  back() {
    this.props.history.goBack();
  }

  verifyMobileNumber() {
    const langType = LanguageUtils.getLangType(this.props);
    let indexValue = this.props.location.state.index || '0';
    let flag = this.props.location.state.flag;
    const { mobileNumber, driverName, trailerNumber, trailerId, trailerSize, vistType } = this.state;
    if ((mobileNumber && driverName && trailerNumber && trailerId && trailerSize) || (vistType === "PL" && (this.props.masterData && this.props.masterData.delivery[indexValue][0].trailer_details.trailer_number) && mobileNumber && driverName) || (flag == false && mobileNumber && driverName)) {
      if (mobileNumber.length === 10 && mobileNumber.match("^[0-9]*$")) {
        this.openVerifyModal();
      } else {
        AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.ValidMobile);
      }
    } else {
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.EnterDetails);
    }
  }

  getTrailerType(lang) {
    if (this.state.trailerTypeList && this.state.trailerTypeList[lang]) {
      const trailerDetails = this.state.trailerTypeList[lang].find(val => val.trailerId === this.state.trailerId);
      return trailerDetails && trailerDetails['trailerType'] || '';
    }
    return '';
  }

  handleConfirm() {
    const langType = LanguageUtils.getLangType(this.props);
    let index = this.props.location.state.index || 0;
    let flag = this.props.location.state.flag;
    const { mobileNumber, driverName, trailerNumber, trailerId, trailerType, liftGate, trailerSize, vistType } = this.state;
    if (
      (driverName &&
        mobileNumber &&
        trailerNumber &&
        trailerType &&
        trailerSize &&
        liftGate &&
        this.props.location.state.reference_id) ||
      (vistType === "PL" && (this.props.masterData && this.props.masterData.delivery[index][0].trailer_details.trailer_number) && mobileNumber && driverName) ||
      (flag == false && mobileNumber && driverName)
    ) {
      const deliveryDetailObj = {
        driverName: this.state.driverName,
        mobileNumber: this.state.mobileNumber,
        trailerNumber: this.state.trailerNumber ? this.state.trailerNumber : (this.props.masterData ? this.props.masterData.delivery[index][0].trailer_details.trailer_number : ''),
        trailerType: this.getTrailerType(langType) || "",
        trailerSize: this.state.trailerSize || "",
        liftGate: this.state.liftGate || "",
        reference_id: this.props.location.state.reference_id || ""
      };
      this.props.actions.deliveryData(deliveryDetailObj);
      // let index = this.props.location.state.index || 0;

      this.props.history.push({
        pathname: ROUTES.TERMSANDCONDITION,
        state: {
          index: index,
          didQueryReturnTrailerNumber: this.props.location.state.didQueryReturnTrailerNumber
        }
      });
      // this.props.history.push(ROUTES.TERMSANDCONDITION);
    } else {
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.EnterDetails);
    }
  }

  showtrailerFields() {
    const langType = LanguageUtils.getLangType(this.props);
    const { vistType } = this.state;
    let indexValue = this.props.location.state.index || 0;
    let flag = this.props.location.state.flag;
    return (
      (flag && !(vistType === "PL" && this.props.masterData && this.props.masterData.delivery[indexValue][0].trailer_details.trailer_number)) &&
      <React.Fragment>
        <div className="form-group">
          <input
            type="text"
            onFocus={() => this.setActiveInput("input3")}
            value={this.state.input["input3"] || ""}
            placeholder={LANGCONSTANT[langType].LABELS.TrailerNumber}
            className="form-control"
            defaultValue={this.state.trailerNumber}
            name="trailerNumber"
            onChange={event => this.handleChange(event)}
          />
        </div>
        <div className="form-group d-flex">
          <strong>{LANGCONSTANT[langType].LABELS.TrailerType}</strong>
          <select name='trailerId' className="form-control" onChange={(event) => this.handleDataChange(event)} value={this.state.trailerId}>
            {
              this.state.trailerTypeList && this.state.trailerTypeList[langType].map((data, index) =>
                <option key={data.trailerId}
                  value={data.trailerId}
                >{data.trailerType}</option>, this)
            }
          </select>
          <strong>{LANGCONSTANT[langType].LABELS.TrailerSize}</strong>
          <select
            name="trailerSize"
            className="form-control"
            onChange={event => this.handleDataChange(event)}
            value={this.state.trailerSize}
          >
            {this.state.trailerSizeList.map(
              (name, index) => (
                <option
                  className='val'
                  key={name.value}
                  value={name.value}
                  defaultValue={this.props.masterData ? this.props.masterData.delivery[this.props.location.state.index || 0][0].trailer_details.trailer_length : ''}
                >
                  {name.value}
                </option>
              ),
              this
            )}
          </select>
        </div>
        <div className="form-group">
          <strong>{LANGCONSTANT[langType].LABELS.LiftGate}</strong>
          <label className="radio-group">
            <input
              type="radio"
              value="yes"
              onChange={event => this.handleDataChange(event)}
              name="liftGate"
            />
            {LANGCONSTANT[langType].BUTTONS.Yes}
          </label>
          <label className="radio-group">
            <input
              type="radio"
              value="no"
              onChange={event => this.handleDataChange(event)}
              name="liftGate"
              defaultChecked
            />
            {LANGCONSTANT[langType].BUTTONS.No}
          </label>
        </div>
      </React.Fragment>
    )
  }

  // ------------------------------------------------------------------------------------

  handleChange(event) {
    let inputVal = event.target.value;

    let updatedInputObj = {
      ...this.state.input,
      [this.state.inputName]: inputVal
    };

    this.setState(
      {
        input: updatedInputObj
      },
      () => {
        this.keyboardRef.keyboard.setInput(inputVal);
      }
    );
  }

  onChangeAll = inputObj => {
    this.setState({
      input: inputObj,
      driverName: inputObj.input1,
      mobileNumber: inputObj.input2,
      trailerNumber: inputObj.input3
    });
  };

  onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();
    if (button === "{enter}") this.verifyMobileNumber();
  };

  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

  setActiveInput = inputName => {
    this.setState(
      {
        inputName: inputName
      },
      () => { }
    );
  };



  render() {
    const langType = LanguageUtils.getLangType(this.props);
    const { vistType } = this.state;
    let indexValue = this.props.location.state.index || 0;
    return (
      <div>
        <IdleTimerComponent />
        <Header history={this.props.history} />
        <div className="body-container">
          <section>
            <div className="checkout-process-tabber">
              <div className="checkout-process-wrapper">
                <div className="checkout-process-container container-890 white-box delivery-detail">
                  <h4>{LANGCONSTANT[langType].STRINGS.EnterBelowDetails}</h4>
                  <form className="d-flex flex-wrap">
                    <div className="form-group">
                      <input
                        type="text"
                        onFocus={() => this.setActiveInput("input1")}
                        value={this.state.input["input1"] || ""}
                        defaultValue={this.state.driverName}
                        placeholder={LANGCONSTANT[langType].LABELS.DriverName}
                        className="form-control"
                        name="driverName"
                        onChange={event => this.handleChange(event)}
                        autoFocus={true}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        onFocus={() => this.setActiveInput("input2")}
                        value={this.state.input["input2"] || ""}
                        placeholder={LANGCONSTANT[langType].LABELS.PhoneNumber}
                        maxLength="10"
                        minLength="10"
                        defaultValue={this.state.mobileNumber}
                        className="form-control"
                        name="mobileNumber"
                        onKeyPress={event => this.checkNumber(event)}
                        onChange={event => this.handleChange(event)}
                      />
                    </div>
                    {this.showtrailerFields()}
                    <div className="text-right btn-wrapper ">
                      <input
                        type="button"
                        className="btn btn-disable"
                        onClick={() => this.back()}
                        value={LANGCONSTANT[langType].BUTTONS.Back}
                      />
                      <input
                        type="button"
                        className="btn btn-submit"
                        onClick={() => this.verifyMobileNumber()}
                        value={LANGCONSTANT[langType].BUTTONS.Confirm}
                      />
                    </div>
                  </form>
                  <div className="keyboard d-flex">
                    <Keyboard
                      ref={r => (this.keyboardRef = r)}
                      inputName={this.state.inputName}
                      syncInstanceInputs={true}
                      display={{
                        "{bksp}": "backspace",
                        "{enter}": " enter",
                        "{space}": "space"
                      }}
                      layout={{
                        default: ["q w e r t y u i o p {bksp}", "a s d f g h j k l {enter}", "z x c v b n m", "{space}"]
                      }}
                      onChangeAll={inputObj => this.onChangeAll(inputObj)}
                      onKeyPress={button => this.onKeyPress(button)}
                    />
                    <div className="keyboard2-container">
                      <Keyboard
                        syncInstanceInputs={true}
                        inputName={this.state.inputName}
                        baseClass={"keyboard2"}
                        layout={{
                          default: ["1 2 3", "4 5 6", "7 8 9", "0"]
                        }}
                        onChangeAll={inputObj => this.onChangeAll(inputObj)}
                        onKeyPress={button => this.onKeyPress(button)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="progress-bar-bottom">
            <PageProgressComponent
              pageName={LANGCONSTANT[langType].LABELS.CheckIn}
              pageNumber={this.state.pageNumber}
              totalPage={4}
              pageNameList={[
                LANGCONSTANT[langType].LABELS.ScanGatePass,
                LANGCONSTANT[langType].LABELS.SelectAppointment,
                LANGCONSTANT[langType].LABELS.EnterDetails,
                LANGCONSTANT[langType].LABELS.AcceptTC
              ]}
            />
          </div>
        </div>
        <div>
          <Modal
            isOpen={this.state.verifyModalIsOpen}
            onAfterOpen={() => this.afterVerifyOpenModal()}
            onRequestClose={() => this.closeVerifyModal()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal"
          >
            <div className="modal confirmation-modal-dialog">
              <div className="delivery-access-body">
                <p ref={subtitle => (this.subtitle = subtitle)}>
                  {LANGCONSTANT[langType].STRINGS.ConfirmNumber}
                </p>
                <div className="form-group" style={{ textAlign: "center" }}>
                  {this.state.mobileNumber}
                  <p>{LANGCONSTANT[langType].STRINGS.SendNotification}</p>
                </div>
                <div className="d-flex">
                  <button
                    className="btn btn-disable"
                    onClick={() => this.closeVerifyModal()}
                  >
                    {LANGCONSTANT[langType].BUTTONS.Cancel}
                  </button>
                  <button
                    className="btn btn-submit"
                    onClick={() => this.handleConfirm()}
                  >
                    {LANGCONSTANT[langType].BUTTONS.Confirm}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

CheckinDeliveryDetail.propTypes = {
  actions: propTypes.object.isRequired
};

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(dataAction, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckinDeliveryDetail);
