import React, { Component } from "react";
import { CONFIG } from "../../configuration/config";
import { CallApi } from "../../services/service";
import * as ROUTES from "../../constants/routes";
import PageProgressComponent from "../../common/PageProgressComponent";
import { spinnerName } from "../../constants/data";
import { spinnerService } from "../../services/spinner.service";
import propTypes from "prop-types";
import { connect } from "react-redux";
import * as LANGCONSTANT from "../../constants/language";
import LanguageUtils from "../../utils/LanguageUtils";
import * as checkoutAction from "../../redux/actions/checkoutAction";
import * as guardAction from "../../redux/actions/guardAction";
import * as dataAction from "../../redux/actions/checkoutShipmentData";
import Header from "../../common/Header";
import IdleTimerComponent from "../../common/idleCheck/IdleTimerComponent";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import AlertModal from "../../common/modals/AlertModal";
import StarRatingComponent from "react-star-rating-component";
import { setData, getData } from "../../containers/storageHandler";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};
class ExitFlowIntermediate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 2,
      trailerNumber: "",
      visitType: "",
      layoutName: "default",
      inputName: "input1",
      input: {},
      modalIsOpen: false,
      rating: 0,
      index: 0,
      feedBackText: "",
      configurationData: getData("configureData"),
      visitTypeList: {
        'ENG': LANGCONSTANT['ENG'].EXITFLOWVISITTYPES,
        'ESP': LANGCONSTANT['ESP'].EXITFLOWVISITTYPES 
      },
      pageNameList: [
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ExitGate,
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.DeliveryDetails
      ]
    };
  }
  componentWillMount() {
    this.setState({
      index: this.props.location.state ? this.props.location.state.index : 0
    });
  }

  onStarClick(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#6495ed";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  submitFeedback() {
    this.setState({ modalIsOpen: false });
    const feedBackObj = {
      profile_id: this.state.configurationData ? this.state.configurationData.facilityId : "",
      comments: this.state.feedBackText,
      ratings: this.state.rating
    };
    if (feedBackObj.profile_id !== "") {
      const langType = LanguageUtils.getLangType(this.props);
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      CallApi("POST", CONFIG.feedBack, {}, feedBackObj, langType, "returnFeedback", this);
    }
  }

  returnFeedback = response => {
    if (response.success === true) {
      this.props.history.push(ROUTES.EXITGATE);
    } else {
      AlertModal.showAlert(response.message);
    }
  };

  cancel() {
    this.closeModal();
    this.props.history.push(ROUTES.EXITGATE);
  }

  handleSubmit() {
    let { visitType } = this.state;
    const langType = LanguageUtils.getLangType(this.props);
    // const dataObj = {
    //   guard: true
    // };
    // this.props.dispatch(guardAction.guardData(dataObj));
    if (visitType === '0' || visitType === '2') {
      let type = visitType === '0' ? 'DE' : 'LU'
      this.checkGateCall(langType, type);
    } else {
      this.exitGateCall(langType);
    }
  }

  checkGateCall(langType, visitType) {
    this.props.dispatch(checkoutAction.checkoutData(getData("configureData")));
    const dataObj = {
      guard: true,
      trailerNumber: this.state.trailerNumber
    };
    this.props.dispatch(guardAction.guardData(dataObj));
    const shipmentCheckoutObj = {
      reference_id: this.state.trailerNumber,
      language: this.props.languageData ? this.props.languageData.language : "en",
      org_id: this.state.configurationData ? this.state.configurationData.facilityId : "",
      customer_load: 'No',
      visit_type: visitType
    };
    if (this.state.trailerNumber) {
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      CallApi("POST", CONFIG.queryCheckout, {}, shipmentCheckoutObj, langType, "returnQueryCheckout", this);
    } else {
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.CorrectTrailerNumber);
    }
  }

  returnQueryCheckout = response => {
    if (response.success === true) {
      setData('isBOL', 'No');
      this.props.dispatch(dataAction.checkoutShipmentData(response.result));
      this.props.history.push(ROUTES.CONFIRMTRAILERDETAILS);
    }
  }

  exitGateCall(langType) {
    let index = getData('laneIndex') || '';
    const exitObj = {
      language: this.props.languageData ? this.props.languageData.language : "en",
      reference_id: "",
      org_id: this.state.configurationData ? this.state.configurationData.facilityId : "",
      lane_no: index // this.state.configurationData ? this.state.configurationData.laneData[this.state.index].lane_no : "",
    };
    if (this.state.trailerNumber && this.state.visitType) {
      if (exitObj.org_id && exitObj.lane_no) {
        spinnerService.show(spinnerName.LOGIN_SPINNER);
        CallApi("POST", CONFIG.exitGate, {}, exitObj, langType, "exitGateCallback", this);
      }
    } else {
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.EnterDetails);
    }
  }

  exitGateCallback = (response) => {
    const langType = LanguageUtils.getLangType(this.props);
    const travelObj = {
      trailer_no: this.state.trailerNumber,
      delivery_no: '' //this.state.visitType
    };
    if (this.state.trailerNumber && this.state.visitType) {
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      CallApi('POST', CONFIG.saveExitGateData, {}, travelObj, langType, "returnSaveExitGateData", this);
    }
  };

  returnSaveExitGateData = response => {
    if (response.success === true) {
      this.openModal();
    } else {
      AlertModal.showAlert(response.message);
    }
  };

  back() {
    this.props.history.goBack();
  }

  handleDataChange(event) {
    let { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleChange(event) {
    let inputVal = event.target.value;

    let updatedInputObj = {
      ...this.state.input,
      [this.state.inputName]: inputVal
    };

    this.setState(
      {
        input: updatedInputObj
      },
      () => {
        this.keyboardRef.keyboard.setInput(inputVal);
      }
    );
  }

  onChangeAll = inputObj => {
    this.setState({
      input: inputObj,
      trailerNumber: inputObj.input1
    });
  };

  onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();
    if (button === "{enter}") this.handleSubmit();
  };

  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

  setActiveInput = inputName => {
    this.setState(
      {
        inputName: inputName
      },
      () => { }
    );
  };

  render() {
    const langType = LanguageUtils.getLangType(this.props);
    const { rating } = this.state;
    return (
      <div>
        <IdleTimerComponent />
        <Header history={this.props.history} />
        <div className="body-container  keyboard-container">
          {/* <div className="gate-label">Empty Trailer Checkout</div> */}
          <section>
            <div className="checkout-process-tabber">
              <div className="checkout-process-wrapper">
                <div className="checkout-process-container container-890 white-box delivery-detail">
                  <h4>{LANGCONSTANT[langType].LABELS.DeliveryDetails}</h4>
                  <form action="" className="d-flex flex-wrap">
                    <div className="form-group">
                      <input
                        type="text"
                        onFocus={() => this.setActiveInput("input1")}
                        value={this.state.input["input1"] || ""}
                        placeholder={LANGCONSTANT[langType].LABELS.TrailerNumber}
                        className="form-control"
                        name="trailerNumber"
                        onChange={event => this.handleChange(event)}
                        autoFocus={true}
                      />
                    </div>
                    <div className="form-group">
                      <label className="wrap">
                        <select name='visitType' className="form-control" value={this.state.visitType} onChange={(event) => this.handleDataChange(event)}>
                          <option value='' selected hidden>{LANGCONSTANT[langType].LABELS.SelectVisitType}</option>
                          {
                            this.state.visitTypeList[LanguageUtils.getLangType(this.props)].map((data, index) =>
                              <option key={index}
                                value={index}>{data}</option>, this)
                          }
                        </select>
                      </label>
                      {/* &#8964; */}
                      {/* <input
                        type="text"
                        onFocus={() => this.setActiveInput("input2")}
                        value={this.state.input["input2"] || ""}
                        placeholder={LANGCONSTANT[langType].LABELS.DeliveryNumber}
                        className="form-control"
                        name="licenseNumber"
                        onChange={event => this.handleChange(event)}
                      /> */}
                    </div>
                    <div className="btn-wrapper text-right">
                      <div className="btn btn-disable" onClick={() => this.back()}>
                        {LANGCONSTANT[langType].BUTTONS.Back}
                      </div>
                      <div className="btn btn-submit" onClick={() => this.handleSubmit()}>
                        {LANGCONSTANT[langType].BUTTONS.Confirm}
                      </div>
                    </div>
                  </form>
                  <div className="keyboard d-flex">
                    <Keyboard
                      ref={r => (this.keyboardRef = r)}
                      inputName={this.state.inputName}
                      syncInstanceInputs={true}
                      display={{
                        "{bksp}": "backspace",
                        "{enter}": " enter",
                        "{space}": "space"
                      }}
                      layout={{
                        default: [
                          // "1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                          "q w e r t y u i o p {bksp}",
                          "a s d f g h j k l {enter}",
                          "z x c v b n m",
                          "{space}"
                        ]
                      }}
                      onChangeAll={inputObj => this.onChangeAll(inputObj)}
                      onKeyPress={button => this.onKeyPress(button)}
                    />
                    <div className="keyboard2-container">
                      <Keyboard
                        syncInstanceInputs={true}
                        inputName={this.state.inputName}
                        baseClass={"keyboard2"}
                        layout={{
                          default: ["1 2 3", "4 5 6", "7 8 9", "0"]
                        }}
                        onChangeAll={inputObj => this.onChangeAll(inputObj)}
                        onKeyPress={button => this.onKeyPress(button)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="progress-bar-bottom">
            <PageProgressComponent
              pageName={LANGCONSTANT[langType].LABELS.CheckOut}
              pageNumber={this.state.pageNumber}
              totalPage={2}
              pageNameList={
                [
                  LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ExitGate,
                  LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.DeliveryDetails
                ]
              }
            />
          </div>
        </div>
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={() => this.afterOpenModal}
            onRequestClose={() => this.closeModal}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal">
            <div className="modal feedback-modal text-center">
              <h2 ref={subtitle => (this.subtitle = subtitle)}>{LANGCONSTANT[langType].STRINGS.CheckoutComplete}</h2>
              <p className="feedback-msg">{LANGCONSTANT[langType].STRINGS.FeedBackMsg}</p>
              <p>
                <b>{LANGCONSTANT[langType].STRINGS.RateExperience}</b>
              </p>
              <div className="rating-stars">
                <StarRatingComponent
                  name="rate1"
                  starCount={5}
                  emptyStarColor={"#ffffff"}
                  value={rating}
                  onStarClick={this.onStarClick.bind(this)}
                />
              </div>
              <div className="btn-alignment">
                <button onClick={() => this.cancel()}>{LANGCONSTANT[langType].BUTTONS.Cancel}</button>
                <button onClick={() => this.submitFeedback()}>{LANGCONSTANT[langType].BUTTONS.Submit}</button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
};

ExitFlowIntermediate.propTypes = {
  dispatch: propTypes.func.isRequired
};

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

export default connect(mapStateToProps)(ExitFlowIntermediate);
