import React from 'react';
import * as LANGCONSTANT from '../../constants/language';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

export const ConfirmationModal = ({ alertModalIsOpen, confirm, closeAlertModal, index, name, phone, delivery, destination, langType = 'ENG' }) => {
  return (
    <div>
      <Modal
        isOpen={alertModalIsOpen}
        onRequestClose={closeAlertModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="modal confirmation-modal-dialog" style={{ minWidth: '520px' }}>
          <div className="delivery-access-body">
            <p style={{ color: '#6495ed' }} />
            <div className="form-group">
              <p>{LANGCONSTANT[langType].STRINGS.ConfirmCheckOutData}:</p>
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '10px', fontWeight: '600' }}>
                  <span>{LANGCONSTANT[langType].LABELS.Name}: </span>
                  {phone &&
                    <span>{LANGCONSTANT[langType].LABELS.PhoneNo}: </span>}
                  {delivery &&
                    <span>{LANGCONSTANT[langType].LABELS.Delivery}: </span>}
                  {destination &&
                    <span>{LANGCONSTANT[langType].LABELS.Destination}: </span>}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', fontWeight: '500' }}>
                  <span>{name}</span>
                  {phone &&
                    <span>{phone}</span>}
                  {delivery &&
                    <span>{delivery}</span>}
                  {destination &&
                    <span>{destination}</span>}
                </div>
              </div>
            </div>
            <div className="d-flex">
              <button
                className="btn btn-disable"
                onClick={() => closeAlertModal()}
                style={{ backgroundColor: "#797979" }}>
                {LANGCONSTANT[langType].BUTTONS.Cancel}
              </button>
              <button className="btn btn-submit" onClick={() => confirm(index)}>
                {LANGCONSTANT[langType].BUTTONS.Ok}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}